.preview-card{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1c7e60;
}

@media screen and (max-width: 599px) {
    .preview-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .preview-card {
        width: 80vw;
        height: 80vw;
        border-radius: 80%;
        border: solid 10px #dbaa74;
        overflow: hidden;
    }
    
    .artist-preview-image {
        height: 105%;
        width: auto;
    }

    .single-preview-card{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 60%;
    }

    .artist-preview-name, .artist-preview-instrument{
        font-family: LarkFont;
        font-size: 1.5rem;
        color: #1c7e60;
    }

    .artist-preview-name{
        margin-top: 20px;
    }

    .artist-preview-instrument{
        margin-bottom: 20px;
    }
}

@media screen and (min-width: 600px) and (max-width: 899px) {
    .preview-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 40px;
        margin-right: 40px;
        margin-bottom: 40px;
    }

    .artists-wrapper{
        display: flex;
        flex-wrap: wrap;
    }

    .preview-card {
        width: 30vw;
        height: 30vw;
        border-radius: 80%;
        border: solid 10px #dbaa74;
        overflow: hidden;
    }
    
    .artist-preview-image {
        height: 105%;
        width: auto;
    }

    .single-preview-card{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 60%;
    }

    .artist-preview-name, .artist-preview-instrument{
        font-family: LarkFont;
        font-size: 1.5rem;
        color: #1c7e60;
    }

    .artist-preview-name{
        margin-top: 20px;
    }

    .artist-preview-instrument{
        margin-bottom: 20px;
    }
}

@media screen and (min-width: 900px) and (max-width: 1199px) {
    .preview-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 40px;
        margin-right: 40px;
        margin-bottom: 40px;
    }

    .artists-wrapper{
        display: flex;
        flex-wrap: wrap;
    }

    .preview-card {
        width: 30vw;
        height: 30vw;
        border-radius: 80%;
        border: solid 10px #dbaa74;
        overflow: hidden;
    }
    
    .artist-preview-image {
        height: 105%;
        width: auto;
    }

    .single-preview-card{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 60%;
    }

    .artist-preview-name, .artist-preview-instrument{
        font-family: LarkFont;
        font-size: 1.5rem;
        color: #1c7e60;
    }

    .artist-preview-name{
        margin-top: 20px;
    }

    .artist-preview-instrument{
        margin-bottom: 20px;
    }
}

@media screen and (min-width: 900px) and (max-width: 1199px) {
    .preview-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 40px;
        margin-right: 40px;
        margin-bottom: 40px;
    }

    .artists-wrapper{
        display: flex;
        flex-wrap: wrap;
    }

    .preview-card {
        width: 30vw;
        height: 30vw;
        border-radius: 80%;
        border: solid 10px #dbaa74;
        overflow: hidden;
    }
    
    .artist-preview-image {
        height: 105%;
        width: auto;
    }

    .single-preview-card{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 60%;
    }

    .artist-preview-name, .artist-preview-instrument{
        font-family: LarkFont;
        font-size: 1.5rem;
        color: #1c7e60;
    }

    .artist-preview-name{
        margin-top: 20px;
    }

    .artist-preview-instrument{
        margin-bottom: 20px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
    .preview-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 40px;
        margin-right: 40px;
        margin-bottom: 40px;
    }

    .artists-wrapper{
        display: flex;
        flex-wrap: wrap;
    }

    .preview-card {
        width: 25vw;
        height: 25vw;
        border-radius: 80%;
        border: solid 10px #dbaa74;
        overflow: hidden;
    }
    
    .artist-preview-image {
        height: 105%;
        width: auto;
    }

    .single-preview-card{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 60%;
    }

    .artist-preview-name, .artist-preview-instrument{
        font-family: LarkFont;
        font-size: 1.5rem;
        color: #1c7e60;
    }

    .artist-preview-name{
        margin-top: 20px;
    }

    .artist-preview-instrument{
        margin-bottom: 20px;
    }
}

@media screen and (min-width: 1400px){
    .preview-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 40px;
        margin-right: 40px;
        margin-bottom: 40px;
    }

    .artists-wrapper{
        display: flex;
        flex-wrap: wrap;
    }

    .preview-card {
        width: 25vw;
        height: 25vw;
        border-radius: 80%;
        border: solid 10px #dbaa74;
        overflow: hidden;
    }
    
    .artist-preview-image {
        height: 105%;
        width: auto;
    }

    .single-preview-card{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 60%;
    }

    .artist-preview-name, .artist-preview-instrument{
        font-family: LarkFont;
        font-size: 1.5rem;
        color: #1c7e60;
    }

    .artist-preview-name{
        margin-top: 20px;
    }

    .artist-preview-instrument{
        margin-bottom: 20px;
    }
}