@font-face {
    font-family: 'Oseberg';
    src: url('../assets/Oseberg.ttf') format('truetype');
}

.flip-container {
    perspective: 1000px;
  }
  
  .flipper {
    transition: transform 0.6s;
    transform-style: preserve-3d;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .flipper-image{
    height: 100%;
    width: 100%;
  }
  
  .flipped {
    transform: rotateX(180deg);
  }
  
  .front, .back {
    backface-visibility: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  
  .back {
    transform: rotateX(180deg);
  }

.logo-wrapper{
    display: flex;
    align-items: center;
    background-color: #18371f;
}

.logo{
    background-color: #18371f;
}

.nav-container{
    position: fixed;
    top: 0;
}

.header {
    display: flex;
    flex-direction: row;
    top: 0;
    z-index: 3;
    background-color: #18371f;
    position: relative;
}

.dropdown{
    display: flex;
    flex-direction: column;
    font-family: larkFont;
}

.desktop-icon{
    font-family: Oseberg;
}

@media (max-width: 375px) {
    .icon{
        width: 60px;
        height: 60px;
        color: white;
    }

    .nav-container{
        width: 100vw;
        height: 100px;
        z-index: 2;
    }

    .header {
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: space-between;
        z-index: 4;
    }

    .menu-icon{
        width: 30px;
        height: 30px;
        position: absolute;
        right: 0;
        margin-right: 20px;
    }

    .nav-icon{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .dropdown {
        margin-top: 100px;
        position: absolute;
        transition: top 1s ease;
        height: calc(100vh - 100px);
        width: 100vw;
        background-color: #dbaa74;
        color: #18371f;
        z-index: 3;
    }

    .invisible {
        top: -100vh;
    }

    .visible {
        top: 0;
    }

    .inactive {
        opacity: 0;
        transition: opacity 1s ease;
        transition-delay: 1s;
    }

    .active {
        opacity: 1;
        transition: opacity 2s ease;
        transition-delay: .4s;
    }

    .nav-link {
        text-decoration: none;
        color: #18371f;
        margin-top: 40px;
        font-size: 1.5rem;
    }
    
    .icon {
        position: absolute;
        right: 0;
        margin-right: 10px;
    }

    .logo {
        position: absolute;
        left: 0;
        margin-left: 5vw;
        align-self: center;
        height: 80%;
        width: auto;
    }

    .desktop-icon{
        display: none;
    }
}

@media screen and (min-width: 376px) and (max-width: 699px){
    .icon{
        width: 60px;
        height: 60px;
        color: white;
    }

    .nav-container{
        width: 100vw;
        height: 100px;
        z-index: 2;
    }

    .header {
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: space-between;
        z-index: 4;
    }

    .menu-icon{
        width: 40px;
        height: 40px;
        position: absolute;
        right: 0;
        margin-right: 20px;
    }

    .nav-icon{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .dropdown {
        margin-top: 100px;
        position: absolute;
        transition: top 1s ease;
        height: calc(100vh - 100px);
        width: 100vw;
        background-color: #dbaa74;
        color: #18371f;
        z-index: 3;
    }

    .invisible {
        top: -100vh;
    }

    .visible {
        top: 0;
    }

    .inactive {
        opacity: 0;
        transition: opacity 1s ease;
        transition-delay: 1s;
    }

    .active {
        opacity: 1;
        transition: opacity 2s ease;
        transition-delay: .4s;
    }

    .nav-link {
        text-decoration: none;
        color: #18371f;
        margin-top: 60px;
        font-size: 1.5rem;
    }
    
    .icon {
        position: absolute;
        right: 0;
        margin-right: 10px;
    }

    .logo {
        position: absolute;
        left: 0;
        margin-left: 5vw;
        align-self: center;
        height: 80%;
        width: auto;
    }

    .desktop-icon{
        display: none;
    }
}

@media screen and (min-width: 700px) and (max-width: 899px) {
    .icon{
        width: 60px;
        height: 60px;
        color: white;
    }

    .nav-container{
        width: 100vw;
        height: 100px;
        z-index: 2;
    }

    .header {
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: space-between;
        z-index: 4;
    }

    .nav, .menu-icon{
        display: none;
    }

    .logo-wrapper{
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 5vw;
    }
    
    .logo {
        height: 80%;
        width: auto; 
    }

    .desktop-icon{
        color:#dbaa74;
        font-size: 1.6rem;
        margin-left: 5px;
        margin-right: 5px;
    }
}

@media screen and (min-width: 900px) and (max-width: 1199px) {
    .icon{
        width: 60px;
        height: 60px;
        color: white;
    }

    .nav-container{
        width: 100vw;
        height: 100px;
        z-index: 2;
    }

    .header {
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: space-evenly;
        z-index: 4;
    }

    .nav, .menu-icon{
        display: none;
    }

    .logo-wrapper{
        height: 100%;
    }
    
    .logo {
        height: 80%;
        width: auto;
        margin-left: 5vw;
    }

    .desktop-wrapper{
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        width: 800px;
        margin-right: 40px;
    }
    .desktop-icon{
        color:#dbaa74;
        font-size: 1.6rem;
        margin-left: 15px;
        margin-right: 15px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
    .icon{
        width: 60px;
        height: 60px;
        color: white;
    }

    .nav-container{
        width: 100vw;
        height: 100px;
        z-index: 2;
    }

    .header {
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: space-evenly;
        z-index: 4;
    }

    .nav, .menu-icon{
        display: none;
    }

    .logo-wrapper{
        height: 100%;
    }
    
    .logo {
        height: 80%;
        width: auto;
        margin-left: 5vw;
    }

    .desktop-wrapper{
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        width: 800px;
        margin-right: 40px;
    }
    .desktop-icon{
        color:#dbaa74;
        font-size: 1.6rem;
        margin-left: 15px;
        margin-right: 15px;
    }
}

/* Styles for screens larger than 1400px */
@media screen and (min-width: 1400px) {
    .icon{
        width: 60px;
        height: 60px;
        color: white;
    }

    .nav-container{
        width: 100vw;
        height: 100px;
        z-index: 2;
    }

    .header {
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: space-evenly;
        z-index: 4;
    }

    .nav, .menu-icon{
        display: none;
    }

    .logo-wrapper{
        height: 100%;
        width: 50%;
    }
    
    .logo {
        height: 80%;
        width: auto;
        margin-left: 5vw;
    }

    .desktop-wrapper{
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        width: 800px;
        margin-right: 40px;
    }

    .desktop-icon{
        color:#dbaa74;
        font-size: 1.6rem;
        margin-left: 15px;
        margin-right: 15px;
    }
}
