.teQAzF{
    background-color: #d10000;
}

    .form-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: calc(100vh - 100px);
        margin-top: 100px;
    }

    .form{
        width: 100%;
        height: 100%;
    }