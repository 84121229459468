.youtube-embed{
    margin-top: 20px;
    padding-bottom: 20px;
}

a{
    text-decoration: none;
}

button{
    cursor: pointer;
}

.artist-header-image{
    height: 105%;
    width: auto;
}

@media screen and (max-width: 599px) {
    .artist-card-header{
        margin-top: 100px;
        height: 200px;
        display: flex;
        background-color: #dbaa74;
        align-items: center;
    }

    .artist-card-header-image{
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        height: 150px;
        width: 150px;
        border: solid 5px #18371f;
    }

    .artist-card-header-name{
        font-family: LarkFont;
        font-size: 1.5rem;
        color: #18371f;
    }

    .artist-card-header-instrument{
        font-family: larkFont;
        font-size: 1.2rem;
        color: #18371f;
    }

    .artist-card-description{
        font-family: larkFont;
        color: #18371f;
        font-size: 1rem;
        width: 90%;
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .youtube-embed{
        width: 90%;
    }

    .youtube{
        width: 100%;
    }

    .artist-card-header-info{
        margin-right: 13vw;
    }
}

@media screen and (min-width: 600px) and (max-width: 899px) {
    .artist-card-header{
        margin-top: 100px;
        height: 200px;
        display: flex;
        background-color: #dbaa74;
        align-items: center;
    }

    .artist-card-header-image{
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        height: 150px;
        width: 150px;
        border: solid 5px #18371f;
    }

    .artist-card-header-name{
        font-family: LarkFont;
        font-size: 2rem;
        color: #18371f;
    }

    .artist-card-header-instrument{
        font-family: larkFont;
        font-size: 1.3rem;
        color: #18371f;
    }

    .artist-card-description{
        font-family: larkFont;
        color: #18371f;
        font-size: 1rem;
        width: 70%;
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .youtube-embed{
        width: 70%;
    }

    .youtube{
        height: 400px;
        width: 100%;
    }

    .artist-card-header-info{
        margin-right: 40vw;
    }
}

@media screen and (min-width: 900px) and (max-width: 1199px) {
    .artist-card-header-info{
        margin-right: 40vw;
    }
    
    .artist-card-header{
        margin-top: 100px;
        height: 200px;
        display: flex;
        background-color: #dbaa74;
        align-items: center;
    }

    .artist-card-header-image{
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        height: 150px;
        width: 150px;
        border: solid 5px #18371f;
    }

    .artist-card-header-name{
        font-family: LarkFont;
        font-size: 3rem;
        color: #18371f;
    }

    .artist-card-header-instrument{
        font-family: larkFont;
        font-size: 2rem;
        color: #18371f;
    }

    .artist-card-description{
        font-family: larkFont;
        color: #18371f;
        font-size: 1.2rem;
        width: 70%;
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .youtube-embed{
        width: 70%;
    }

    .youtube{
        width: 100%;
        height: 500px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1399px){
    .artist-card-header-info{
        margin-right: 60vw;
    }
    
    .artist-card-header{
        margin-top: 100px;
        height: 200px;
        display: flex;
        background-color: #dbaa74;
        align-items: center;
    }

    .artist-card-header-image{
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        height: 150px;
        width: 150px;
        border: solid 5px #18371f;
    }

    .artist-card-header-name{
        font-family: LarkFont;
        font-size: 3rem;
        color: #18371f;
    }

    .artist-card-header-instrument{
        font-family: larkFont;
        font-size: 2rem;
        color: #18371f;
    }

    .artist-card-description{
        font-family: larkFont;
        color: #18371f;
        font-size: 1.5rem;
        width: 70%;
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .youtube-embed{
        width: 50%;
    }

    .youtube{
        width: 100%;
        height: 500px;
    }
}

@media screen and (min-width: 1400px){
    .artist-card-header-info{
        margin-right: 60vw;
    }
    
    .artist-card-header{
        margin-top: 100px;
        height: 200px;
        display: flex;
        background-color: #dbaa74;
        align-items: center;
    }

    .artist-card-header-image{
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        height: 150px;
        width: 150px;
        border: solid 5px #18371f;
    }

    .artist-card-header-name{
        font-family: LarkFont;
        font-size: 3rem;
        color: #18371f;
    }

    .artist-card-header-instrument{
        font-family: larkFont;
        font-size: 2rem;
        color: #18371f;
    }

    .artist-card-description{
        font-family: larkFont;
        color: #18371f;
        font-size: 1.5rem;
        width: 50%;
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .youtube-embed{
        width: 50%;
    }

    .youtube{
        width: 100%;
        height: 500px;
    }
}