@media screen and (max-width: 599px) {
    .info-container{
        width: 100%;
        margin-top: 10px;
    }    

    .info-item{
        width: 90%;
        color: #18371f;
        font-family: larkFont;
        margin-top: 20px;
        margin-bottom: 40px;
    }

    .item-header{
        font-size: 1.5rem;
    }

    .item-content{
        font-size: 1.2rem;
    }

    .disclaimer{
        font-size: 1rem;
        font-family: larkFont;
        color: #18371f;
        margin-bottom: 60px;
        width: 90%;
        border-bottom: 3px solid #18371f;
        padding-bottom: 5px;
    }

    .sign-up-button{
        background-color: #18371f;
        color: white;
        font-family: larkFont;
        font-size: 1rem;
        padding: 10px;
        margin-top: 20px;
        border: none;
        border-radius: 5px;
        margin-left: 5%;
    }
}

@media screen and (min-width: 376px) and (max-width: 599px){
    .info-container{
        width: 100%;
        margin-top: 10px;
    }    

    .info-item{
        width: 90%;
        color: #18371f;
        font-family: larkFont;
        margin-top: 20px;
        margin-bottom: 40px;
    }

    .item-header{
        font-size: 1.5rem;
    }

    .item-content{
        font-size: 1.2rem;
    }

    .disclaimer{
        font-size: 1rem;
        font-family: larkFont;
        color: #18371f;
        margin-bottom: 60px;
        width: 90%;
        border-bottom: 3px solid #18371f;
        padding-bottom: 5px;
    }

    .sign-up-button{
        background-color: #18371f;
        color: white;
        font-family: larkFont;
        font-size: 1rem;
        padding: 10px;
        margin-top: 20px;
        border: none;
        border-radius: 5px;
        margin-left: 5%;
    }
}

@media screen and (min-width: 600px) and (max-width: 899px) {
    .info-container{
        width: 100%;
        margin-top: 10px;
    }    

    .info-item{
        width: 60%;
        color: #18371f;
        font-family: larkFont;
        margin-top: 20px;
        margin-bottom: 40px;
    }

    .item-header{
        font-size: 1.5rem;
    }

    .item-content{
        font-size: 1.2rem;
    }

    .disclaimer{
        font-size: 1rem;
        font-family: larkFont;
        color: #18371f;
        margin-bottom: 50px;
        width: 70%;
        border-bottom: 3px solid #18371f;
        padding-bottom: 5px;
        margin-left: 20%;
    }

    .sign-up-button{
        background-color: #18371f;
        color: white;
        font-family: larkFont;
        font-size: 1rem;
        padding: 10px;
        margin-top: 10px;
        border: none;
        border-radius: 5px;
        margin-left: 20%;
    }
}

@media screen and (min-width: 900px) and (max-width: 1199px) {
    .info-container{
        width: 100%;
        margin-top: 10px;
    }    

    .info-item{
        width: 60%;
        color: #18371f;
        font-family: larkFont;
        margin-top: 20px;
        margin-bottom: 40px;
    }

    .item-header{
        font-size: 1.5rem;
    }

    .item-content{
        font-size: 1.2rem;
    }

    .disclaimer{
        font-size: 1rem;
        font-family: larkFont;
        color: #18371f;
        margin-bottom: 50px;
        width: 60%;
        border-bottom: 3px solid #18371f;
        padding-bottom: 5px;
        margin-left: 20%;
    }

    .sign-up-button{
        background-color: #18371f;
        color: white;
        font-family: larkFont;
        font-size: 1rem;
        padding: 10px;
        margin-top: 10px;
        border: none;
        border-radius: 5px;
        margin-left: 20%;
    }
}

@media screen and (min-width: 1200px){
    .info-container{
        width: 100%;
        margin-top: 10px;
    }    

    .info-item{
        width: 60%;
        color: #18371f;
        font-family: larkFont;
        margin-top: 20px;
        margin-bottom: 40px;
    }

    .item-header{
        font-size: 1.5rem;
    }

    .item-content{
        font-size: 1.2rem;
    }

    .disclaimer{
        font-size: 1rem;
        font-family: larkFont;
        color: #18371f;
        margin-bottom: 50px;
        width: 50%;
        border-bottom: 3px solid #18371f;
        padding-bottom: 5px;
        margin-left: 20%;
    }

    .sign-up-button{
        background-color: #18371f;
        color: white;
        font-family: larkFont;
        font-size: 1rem;
        padding: 10px;
        margin-top: 10px;
        border: none;
        border-radius: 5px;
        margin-left: 20%;
    }
}