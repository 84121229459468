* {
    margin: 0 auto;
    padding: 0;
    box-sizing: border-box;
  }
  
  html, body {
    width: 100%;
    height: 100%;
  }

  .button {
    cursor: pointer;
  }

@font-face {
    font-family: "LarkFont";
    src: url('../assets/LinotypeProjektRegular.ttf')
}

@font-face {
    font-family: larkFontBold;
    src: url('../assets/LinotypeProjektBold.ttf');
}

@font-face {
    font-family: oseberg;
    src: url('../assets/Oseberg.ttf');
}

.container {
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.button{
    background-color: #1c7e60;
    color: white;
    border: none;
}

.title-container{
    position: relative;
}

.main-title, .title-text, .title-image {
    position: absolute;
}

.main-title, .title-text{
    z-index: 3;
    font-family: larkFont;
    color: white;
}

.title-image{
    z-index: 1;
}

.dates{
    font-family: larkFont;
    color: #18371f;
}

@media screen and (max-width: 375px) {
    .grant-banner{
        width: 100vw;
        background-color: #dbaa74;
        color: #18371f;
        font-family: larkFont;
        padding: 15px;
        font-size: 1rem;
    }

    .dates{
        margin-top: 20px;
        font-size: 1rem;
    }

    .title-text{
        text-align: center;
    }
    .title-container {
        height: 400px;
        overflow: hidden;
        margin-top: 100px;
    }

    .title-image-container {
        position: relative;
    }
    
    .title-overlay{
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.3);
    }

    .title-image{
        height: 600px;
        width: auto;
        top: 0px;
        left: -115px;
    }

    .main-title{
        height: 150px;
        width: auto;
        top: 20px;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .title-text{
        top: 170px;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 1.2rem;
    }

    .main-navigation{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .main-navigation-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60%;
        border-bottom: #18371f solid 2px;
    }

    .main-navigation-title{
        font-family: larkFont;
        color: #18371f;
        font-size: 2rem;
        margin-top: 60px;
        text-align: center;
    }

    .main-navigation-blurb{
        font-family: larkFont;
        color: #18371f;
        text-align: center;
        font-size: 1rem;
        margin-top: 60px;
    }

    .main-navigation-button{
        margin-top: 60px;
        margin-bottom: 60px;
        background-color: #18371f;
        color: white;
        font-family: larkFont;
        font-style: italic;
        height: 40px;
        min-width: 120px;
        padding: 5px;
        border: none;
        text-align: center;
        font-size: 1.2rem;
        text-align: center;
    }

    .lower-blurb-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 80px;
    }

    .lower-blurb-title{
        font-family: larkFont;
        color: #18371f;
        font-size: 1.8rem;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
    }

    .lower-blurb-text{
        font-family: larkFont;
        color: #18371f;
        font-size: 1.2rem;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 80px;
    }

    .bottom-div{
        background-color: #18371f;
        height: 100px;
        width: 100vw;
        margin-top: 80px;
    }
}

@media screen and (min-width: 376px) and (max-width: 449px) {
    .grant-banner{
        width: 100vw;
        background-color: #dbaa74;
        color: #18371f;
        font-family: larkFont;
        padding: 25px;
        font-size: 1rem;
    }

    .dates{
        margin-top: 20px;
        font-size: 1rem;
    }

    .title-text{
        text-align: center;
    }
    
    .title-container {
        height: 400px;
        overflow: hidden;
        margin-top: 100px;
    }

    .title-image-container {
        position: relative;
    }
    
    .title-overlay{
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.3);
    }

    .title-image{
        height: 600px;
        width: auto;
        top: 0px;
        left: -115px;
    }

    .main-title{
        height: 150px;
        width: auto;
        top: 20px;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .title-text{
        top: 170px;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 1.2rem;
    }

    .main-navigation{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .main-navigation-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60%;
        border-bottom: #18371f solid 2px;
    }

    .main-navigation-title{
        font-family: larkFont;
        color: #18371f;
        font-size: 2rem;
        margin-top: 60px;
        text-align: center;
    }

    .main-navigation-blurb{
        font-family: larkFont;
        color: #18371f;
        text-align: center;
        font-size: 1rem;
        margin-top: 60px;
    }

    .main-button-wrapper{
        margin-top: 60px;
        margin-bottom: 60px
    }

    .main-navigation-button{
        background-color: #18371f;
        color: white;
        font-family: larkFont;
        font-style: italic;
        height: 40px;
        min-width: 120px;
        padding: 5px;
        border: none;
        text-align: center;
        font-size: 1.2rem;
        text-align: center;
    }

    .lower-blurb-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 80px;
        width: 80%;
    }

    .lower-blurb-title{
        font-family: larkFont;
        color: #18371f;
        font-size: 1.8rem;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
    }

    .lower-blurb-text{
        font-family: larkFont;
        color: #18371f;
        font-size: 1rem;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 80px;
    }

    .bottom-div{
        background-color: #18371f;
        height: 100px;
        width: 100vw;
        margin-top: 80px;
    }
}

@media screen and (min-width: 450px) and (max-width: 599px) {
    .grant-banner{
        width: 100vw;
        background-color: #dbaa74;
        color: #18371f;
        font-family: larkFont;
        padding: 30px;
        font-size: 1rem;
    }
    
    .dates{
        margin-top: 20px;
        font-size: 1rem;
    }

    .title-text{
        text-align: center;
    }

    .title-container {
        height: 400px;
        overflow: hidden;
        margin-top: 100px;
    }

    .title-image-container {
        position: relative;
    }
    
    .title-overlay{
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.3);
    }

    .title-image{
        height: 600px;
        width: auto;
        top: 0px;
        left: -115px;
    }

    .main-title{
        height: 150px;
        width: auto;
        top: 20px;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .title-text{
        top: 170px;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 1.4rem;
        width: 205px;
    }

    .main-navigation{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .main-navigation-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60%;
        border-bottom: #18371f solid 2px;
    }

    .main-navigation-title{
        font-family: larkFont;
        color: #18371f;
        font-size: 2rem;
        margin-top: 60px;
        text-align: center;
    }

    .main-navigation-blurb{
        font-family: larkFont;
        color: #18371f;
        text-align: center;
        font-size: 1rem;
        margin-top: 60px;
    }

    .main-button-wrapper{
        margin-top: 60px;
        margin-bottom: 60px
    }

    .main-navigation-button{
        background-color: #18371f;
        color: white;
        font-family: larkFont;
        font-style: italic;
        height: 40px;
        min-width: 120px;
        padding: 5px;
        border: none;
        text-align: center;
        font-size: 1.2rem;
        text-align: center;
    }

    .lower-blurb-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 80px;
        width: 80%;
    }

    .lower-blurb-title{
        font-family: larkFont;
        color: #18371f;
        font-size: 1.8rem;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
    }

    .lower-blurb-text{
        font-family: larkFont;
        color: #18371f;
        font-size: 1.2rem;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 80px;
    }

    .bottom-div{
        background-color: #18371f;
        height: 100px;
        width: 100vw;
        margin-top: 80px;
    }
}

@media screen and (min-width: 600px) and (max-width: 899px) {
    .grant-banner{
        width: 100vw;
        background-color: #dbaa74;
        color: #18371f;
        font-family: larkFont;
        padding: 25px 150px 25px 150px;
        font-size: 1rem;
    }
    
    .dates{
        margin-top: 20px;
        font-size: 1rem;
    }

    .title-container {
        height: 600px;
        overflow: hidden;
        margin-top: 100px;
    }

    .title-image-container {
        position: relative;
    }
    
    .title-overlay{
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.3);
    }

    .title-image{
        height: 800px;
        width: auto;
        top: -10px;
        left: -15px;
    }

    .main-title{
        height: auto;
        width: 300px;
        top: 30px;
        left: 250px;
        transform: translate(-50%, 0);
    }

    .title-text{
        top: 170px;
        left: 242px;
        transform: translate(-50%, 0);
        font-size: 1.2rem;
        width: 275px;
        padding-left: 40px;
    }

    .main-navigation{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .main-navigation-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60%;
        border-bottom: #18371f solid 2px;
    }

    .main-navigation-title{
        font-family: larkFont;
        color: #18371f;
        font-size: 2rem;
        margin-top: 60px;
        text-align: center;
    }

    .main-navigation-blurb{
        font-family: larkFont;
        color: #18371f;
        text-align: center;
        font-size: .9rem;
        margin-top: 60px;
    }

    .main-button-wrapper{
        margin-top: 60px;
        margin-bottom: 60px
    }

    .main-navigation-button{
        background-color: #18371f;
        color: white;
        font-family: larkFont;
        font-style: italic;
        height: 40px;
        min-width: 120px;
        padding: 5px;
        border: none;
        text-align: center;
        font-size: 1.2rem;
        text-align: center;
    }

    .lower-blurb-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 80px;
        width: 80%;
    }

    .lower-blurb-title{
        font-family: larkFont;
        color: #18371f;
        font-size: 1.8rem;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
    }

    .lower-blurb-text{
        font-family: larkFont;
        color: #18371f;
        font-size: 1.2rem;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 80px;
    }

    .bottom-div{
        background-color: #18371f;
        height: 100px;
        width: 100vw;
        margin-top: 80px;
    }
}

@media screen and (min-width: 900px) and (max-width: 1199px) {
    .grant-banner{
        width: 100vw;
        background-color: #dbaa74;
        color: #18371f;
        font-family: larkFont;
        padding: 25px 250px 25px 250px;
        font-size: 1.2rem;
    }
    
    .dates{
        margin-top: 20px;
        font-size: 1rem;
    }

    .title-container {
        height: 600px;
        overflow: hidden;
        margin-top: 100px;
    }

    .title-image-container {
        position: relative;
    }
    
    .title-overlay{
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.3);
    }

    .title-image{
        height: 912px;
        width: auto;
        top: -80px;
        left: -15px 
    }

    .main-title{
        height: auto;
        width: 300px;
        top: 30px;
        left: 250px;
        transform: translate(-50%, 0);
    }

    .title-text{
        top: 170px;
        left: 242px;
        transform: translate(-50%, 0);
        font-size: 1.5rem;
        width: 275px;
        padding-left: 40px;
    }

    .main-navigation{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .main-navigation-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60%;
        border-bottom: #18371f solid 2px;
    }

    .main-navigation-title{
        font-family: larkFont;
        color: #18371f;
        font-size: 3rem;
        margin-top: 60px;
        text-align: center;
    }

    .main-navigation-blurb{
        font-family: larkFont;
        color: #18371f;
        text-align: center;
        font-size: 1.5rem;
        margin-top: 60px;
        max-width: 400px;
    }

    .main-button-wrapper{
        margin-top: 60px;
        margin-bottom: 60px
    }

    .main-navigation-button{

        background-color: #18371f;
        color: white;
        font-family: larkFont;
        font-style: italic;
        height: 40px;
        min-width: 120px;
        padding: 5px;
        border: none;
        text-align: center;
        font-size: 1.2rem;
        text-align: center;
    }

    .lower-blurb-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 80px;
        width: 60%;
    }

    .lower-blurb-title{
        font-family: larkFont;
        color: #18371f;
        font-size: 1.8rem;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
    }

    .lower-blurb-text{
        font-family: larkFont;
        color: #18371f;
        font-size: 1.2rem;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 80px;
    }

    .bottom-div{
        background-color: #18371f;
        height: 100px;
        width: 100vw;
        margin-top: 80px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
    .grant-banner{
        width: 100vw;
        background-color: #dbaa74;
        color: #18371f;
        font-family: larkFont;
        padding: 25px 300px 25px 300px;
        font-size: 1.1rem;
    }
    
    .dates{
        margin-top: 20px;
        font-size: 1rem;
    }

    .title-container {
        height: 600px;
        overflow: hidden;
        margin-top: 100px;
    }

    .title-image-container {
        position: relative;
    }
    
    .title-overlay{
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.3);
    }

    .title-image{
        height: 1100px;
        width: auto;
        top: -180px;
        left: -15px 
    }

    .main-title{
        height: auto;
        width: 300px;
        top: 30px;
        left: 250px;
        transform: translate(-50%, 0);
    }

    .title-text{
        top: 170px;
        left: 242px;
        transform: translate(-50%, 0);
        font-size: 1.5rem;
        width: 275px;
        padding-left: 40px;
    }

    .main-navigation{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .main-navigation-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60%;
        border-bottom: #18371f solid 2px;
    }

    .main-navigation-title{
        font-family: larkFont;
        color: #18371f;
        font-size: 3rem;
        margin-top: 60px;
        text-align: center;
    }

    .main-navigation-blurb{
        font-family: larkFont;
        color: #18371f;
        text-align: center;
        font-size: 1.5rem;
        margin-top: 60px;
        max-width: 400px;
    }

    .main-button-wrapper{
        margin-top: 60px;
        margin-bottom: 60px
    }

    .main-navigation-button{
        background-color: #18371f;
        color: white;
        font-family: larkFont;
        font-style: italic;
        height: 40px;
        min-width: 120px;
        padding: 5px;
        border: none;
        text-align: center;
        font-size: 1.2rem;
        text-align: center;
    }

    .lower-blurb-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 80px;
        width: 60%;
    }

    .lower-blurb-title{
        font-family: larkFont;
        color: #18371f;
        font-size: 1.8rem;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
    }

    .lower-blurb-text{
        font-family: larkFont;
        color: #18371f;
        font-size: 1.2rem;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 80px;
    }

    .bottom-div{
        background-color: #18371f;
        height: 100px;
        width: 100vw;
        margin-top: 80px;
    }
}

@media screen and (min-width: 1400px){
    .grant-banner{
        width: 100vw;
        background-color: #dbaa74;
        color: #18371f;
        font-family: larkFont;
        padding: 35px 300px 35px 300px;
        font-size: 1.5rem;
    }
    
    .dates{
        margin-top: 20px;
        font-size: 1rem;
    }
    
    .title-container {
        height: 600px;
        overflow: hidden;
        margin-top: 100px;
    }

    .title-image-container {
        position: relative;
    }
    
    .title-overlay{
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.3);
    }

    .title-image{
        height: auto;
        left: 0px;
        top: -200px;
        width: 100vw;
    }

    .main-title{
        height: auto;
        width: 300px;
        top: 30px;
        left: 250px;
        transform: translate(-50%, 0);
    }

    .title-text{
        top: 170px;
        left: 242px;
        transform: translate(-50%, 0);
        font-size: 1.5rem;
        width: 275px;
        padding-left: 40px;
    }

    .main-navigation{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .main-navigation-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60%;
        border-bottom: #18371f solid 2px;
    }

    .main-navigation-title{
        font-family: larkFont;
        color: #18371f;
        font-size: 3rem;
        margin-top: 60px;
        text-align: center;
    }

    .main-navigation-blurb{
        font-family: larkFont;
        color: #18371f;
        text-align: center;
        font-size: 1.5rem;
        margin-top: 60px;
        max-width: 400px;
    }

    .main-button-wrapper{
        margin-top: 60px;
        margin-bottom: 60px
    }

    .main-navigation-button{
        background-color: #18371f;
        color: white;
        font-family: larkFont;
        font-style: italic;
        min-width: 120px;
        padding: 5px;
        border: none;
        text-align: center;
        font-size: 1.2rem;
        text-align: center;
    }

    .lower-blurb-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 80px;
        width: 60%;
    }

    .lower-blurb-title{
        font-family: larkFont;
        color: #18371f;
        font-size: 1.8rem;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
    }

    .lower-blurb-text{
        font-family: larkFont;
        color: #18371f;
        font-size: 1.2rem;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 80px;
    }

    .bottom-div{
        background-color: #18371f;
        height: 100px;
        width: 100vw;
        margin-top: 80px;
    }
}